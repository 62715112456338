import { initPjs } from "tsparticles-particles.js";
import { loadBasic } from "tsparticles-basic";
import { loadEasingQuadPlugin } from "tsparticles-plugin-easing-quad";
import { loadExternalAttractInteraction } from "tsparticles-interaction-external-attract";
import { loadExternalBounceInteraction } from "tsparticles-interaction-external-bounce";
import { loadExternalBubbleInteraction } from "tsparticles-interaction-external-bubble";
import { loadExternalConnectInteraction } from "tsparticles-interaction-external-connect";
import { loadExternalGrabInteraction } from "tsparticles-interaction-external-grab";
import { loadExternalPauseInteraction } from "tsparticles-interaction-external-pause";
import { loadExternalPushInteraction } from "tsparticles-interaction-external-push";
import { loadExternalRemoveInteraction } from "tsparticles-interaction-external-remove";
import { loadExternalRepulseInteraction } from "tsparticles-interaction-external-repulse";
import { loadExternalSlowInteraction } from "tsparticles-interaction-external-slow";
import { loadImageShape } from "tsparticles-shape-image";
import { loadLifeUpdater } from "tsparticles-updater-life";
import { loadLineShape } from "tsparticles-shape-line";
import { loadParallaxMover } from "tsparticles-move-parallax";
import { loadParticlesAttractInteraction } from "tsparticles-interaction-particles-attract";
import { loadParticlesCollisionsInteraction } from "tsparticles-interaction-particles-collisions";
import { loadParticlesLinksInteraction } from "tsparticles-interaction-particles-links";
import { loadPolygonShape } from "tsparticles-shape-polygon";
import { loadRotateUpdater } from "tsparticles-updater-rotate";
import { loadSquareShape } from "tsparticles-shape-square";
import { loadStarShape } from "tsparticles-shape-star";
import { loadStrokeColorUpdater } from "tsparticles-updater-stroke-color";
import { loadTextShape } from "tsparticles-shape-text";
export async function loadSlim(engine, refresh = true) {
    initPjs(engine);
    await loadParallaxMover(engine, false);
    await loadExternalAttractInteraction(engine, false);
    await loadExternalBounceInteraction(engine, false);
    await loadExternalBubbleInteraction(engine, false);
    await loadExternalConnectInteraction(engine, false);
    await loadExternalGrabInteraction(engine, false);
    await loadExternalPauseInteraction(engine, false);
    await loadExternalPushInteraction(engine, false);
    await loadExternalRemoveInteraction(engine, false);
    await loadExternalRepulseInteraction(engine, false);
    await loadExternalSlowInteraction(engine, false);
    await loadParticlesAttractInteraction(engine, false);
    await loadParticlesCollisionsInteraction(engine, false);
    await loadParticlesLinksInteraction(engine, false);
    await loadEasingQuadPlugin();
    await loadImageShape(engine, false);
    await loadLineShape(engine, false);
    await loadPolygonShape(engine, false);
    await loadSquareShape(engine, false);
    await loadStarShape(engine, false);
    await loadTextShape(engine, false);
    await loadLifeUpdater(engine, false);
    await loadRotateUpdater(engine, false);
    await loadStrokeColorUpdater(engine, false);
    await loadBasic(engine, refresh);
}
